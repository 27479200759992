import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/LifeSkill2023/2024/CareerGuidance/1.jpg';
import p2 from 'assests/Photos/LifeSkill2023/2024/CareerGuidance/2.jpg';
import p3 from 'assests/Photos/LifeSkill2023/2024/CareerGuidance/3.jpg';
import p4 from 'assests/Photos/LifeSkill2023/2024/CareerGuidance/4.jpg';
// import p5 from 'assests/Photos/LifeSkill2023/Roadsafty/5.jpg';
// import p6 from 'assests/Photos/LifeSkill2023/Roadsafty/6.jpg';
// import p7 from 'assests/Photos/LifeSkill2023/Roadsafty/7.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2023 from 'views/LifeSkill2023/Sidebar2023';
import Sidebar2024 from '../LifeSkill2023/Sidebar2024';
import Archive2022 from '../LifeSkill2023/Archive2022';

const CareerGuidance2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1.2,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1.2,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Meet and Greet Session
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 10  Date: 6 July 2024
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
       
"On 6th July 2024, National Public School, Yeshwanthpur, organized a 'Meet and Greet Session' for the parents of Grade 10 students with the Chairman of the institution, Dr. Shivashankar, and Principal, Ms. Sheeja Manoj. The session was followed by a Career Guidance Programme 2024-25 presented by Loadstar Career Guidance. Ms. Ramya, an established Chartered Accountant and Counsellor at Loadstar, emphasized the importance of career planning. A brainstorming session for career pathways was introduced for the apt combinations opted by the students. Students were also informed about the unique courses offered by a few colleges. The session culminated with the message that the right way to make a decision is to decide on the destination."

        </Typography>
      </Box>
      {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “OPPORTUNITIES DON'T HAPPEN, YOU CREATE THEM”

<br/>
        </Typography> */}

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 270 : 180}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2024/>
             </Box>
             <Box marginBottom={4}>
               <Archive2022/>
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default CareerGuidance2024;